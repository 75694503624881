<script setup lang="ts">
import JobListItem from '@/views/career/JobListItem.vue';
import type { JobModel } from '@/types/jobModel';

defineOptions({
  name: 'CompanyVacancies',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const { fetchAllJobs, jobListRef } = useMopJob();
const vacanciesRef = ref<JobModel[]>([]);
const MAX_TILES_NUMBER = props.data.maxTilesNumber ?? 0;
const FILTERS_COLLECTION = [
  {
    value: props.data.filterCareer,
    name: 'career',
  },
  {
    value: props.data.filterDepartment,
    name: 'department',
  },
  {
    value: props.data.filterCountry,
    name: 'country',
  },
  {
    value: props.data.filterDivision,
    name: 'division',
  },
  {
    value: props.data.filterScope,
    name: 'scope',
  },
];

function filterVacancies(jobs: JobModel[]) {
  return jobs.filter((job) => {
    return FILTERS_COLLECTION.every((filter) => {
      if (filter.value.length === 0) {
        return true;
      }
      return job.containsFilterValue(filter.name, filter.value);
    });
  });
}

onMounted(async () => {
  await fetchAllJobs();
  if (jobListRef.value.length === 0) {
    return;
  }

  vacanciesRef.value = filterVacancies(jobListRef.value).slice(0, MAX_TILES_NUMBER);
});
</script>

<template>
  <div v-if="vacanciesRef.length > 0" v-storyblok-editable="data" class="company-vacancies">
    <div class="company-vacancies__list">
      <JobListItem
        v-for="item in vacanciesRef"
        :key="`vacancies-${item.getId()}`"
        :job="item"
        class="company-vacancies__item"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-vacancies__list {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: $space-8;
  align-content: start;

  @include apply-upto(extralarge) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @include apply-upto(medium) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @include apply-upto(small) {
    grid-template-columns: 1fr;
  }
}

.company-vacancies__item {
  border: 1px solid $black;

  @include apply-from(medium) {
    aspect-ratio: 5 / 6;
  }
}
</style>
